import React from "react";
import { Link } from "gatsby";
import { Result, Button } from "antd";
import Head from "../components/head";
import Footer from "../components/footer";

export default () => {
  return (
    <>
      <div>
        <Head title='Digital Way | Sorry, the page you visited does not exist' />
        <Result
          status='404'
          title='404'
          subTitle='Sorry, the page you visited does not exist.'
          extra={
            <Button type='primary'>
              <Link to='/'>Back Home</Link>
            </Button>
          }
        />
      </div>
      <Footer />
    </>
  );
};
